<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { NuxtPage } from '#components'

import { useColorMode } from '@/composables/useColorMode'
import { useUserPreferences } from '@/composables/useUserPreferences'
import { setBodyFontSize } from '@/utils/userPreferences'

const preferences = useUserPreferences()
useColorMode()

watch(
  () => preferences.value.fontSize,
  (sizePreference) => {
    setBodyFontSize(sizePreference)
  },
  { immediate: true }
)
</script>
